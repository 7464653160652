import KeyValue from './bean/keyValue'
import _ from 'lodash'

export class OrderUtils {
  static shouldTrim(propertyName: string) {
    return ['orderNo'].includes(propertyName)
  }

  static get installationPeriodDdl(): KeyValue<string, string>[] {
    return [
      {
        key: 'MORNING',
        value: 'Morning | 10:30AM - 12:30PM',
      },
      {
        key: 'AFTERNOON',
        value: 'Afternoon | 2:30PM - 5:30PM',
      },
    ]
  }

  static stringToOrderParser(input: string): Record<string, string> {
    function getNric(input: string[]): Record<string, string> {
      const a: string[][] = input.map(o => o.split(':').map(o1 => o1.trim()))

      const [, idType] = a.find(o => o[0] === 'Primary ID Type') || []
      const [, idValue] = a.find(o => o[0] === 'Primary ID Number') || []

      if (idType === 'Business Registration Number') {
        const [, name] = a.find(o => o[0] === 'Name') || []

        return {
          companyRegNo: idValue,
          companyName: name,
        }
      } else {
        if (idValue) return { nric: idValue }
        else return {}
      }
    }

    function getLoginId(input: string): Record<string, string> {
      const unifiRegex = /[a-zA-Z0-9\-._]+@(unifibiz|unifi)$/m

      const result = unifiRegex.exec(input)

      if (result?.length && result[0]) {
        return { loginId: result[0] }
      } else {
        return {}
      }
    }

    function isValidDate(input: string): boolean {
      const dateTimeRegex = /\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}/
      return dateTimeRegex.test(input.trim())
    }

    function parser(input: string): Record<string, string> | undefined {
      const barSeparator = ' | '

      if (input.startsWith('Order Number')) {
        const result = input.replace('Order Number', '').trim()
        if (!result) return
        return { orderNo: result }
      } else if (input.startsWith('Residential Voice')) {
        const result = input.replace('Residential Voice', '').trim()
        if (!result) return
        return { telNo: result }
      } else if (input.startsWith('Business Voice')) {
        const result = input.replace('Business Voice', '').trim()
        if (!result) return
        return { telNo: result }
      } else if (input.split(barSeparator).length === 2) {
        const [dateString, timeString] = input.split(barSeparator)

        const finalDateString = dateString.replace(
          'Installation Appointment',
          ''
        )

        const date = new Date(finalDateString).toISOString()
        return {
          appointmentDate: date,
          appointmentTime: timeString.toUpperCase(),
        }
      } else if (isValidDate(input)) {
        return { appointmentDate: new Date(input).toISOString() }
      }

      const [key, value] = input.split(':').map(o => o.trim())

      if (!value?.trim() || value === '-') return

      switch (key) {
        case 'Name':
          return { fullName: value }
        case 'Phone Number':
          return { mobileNo: [value.slice(0, 3), value.slice(3)].join('-') }
        case 'Email':
          return { email: value }
        case 'House / Unit / Lot':
          return { unitNo: value }
        case 'Floor Number':
          return { floorNo: value }
        case 'Building Name':
          return { buildingName: value }
        case 'Street Type':
          return { streetType: value }
        case 'Street Name':
          return { streetName: value }
        case 'Postcode':
          return { postcode: value }
        case 'Section':
          return { section: value }
        case 'City':
          return { city: value }
        case 'State':
          return { state: _.startCase(value.toLowerCase()) }
        case 'Account Number':
          return { accountNo: value }
        case 'Bundle Name':
          return { packageName: value }
        default:
          return undefined
      }
    }

    const parse: string[] = input.split('\n').filter(Boolean)

    const secondParse = parse
      .map(parser)
      .filter(Boolean)
      .reduce((pre, cur) => {
        return {
          ...pre,
          ...cur,
        }
      }, {})

    return {
      ...secondParse,
      ...getLoginId(input),
      ...getNric(parse),
    }
  }

  static tmStringToOrderParser(input: string): Record<string, string> {
    function process(
      input: string[],
      { startWith, key, endWith }: Record<string, string>
    ): Record<string, string> {
      const indexOfResult = input.findIndex(value =>
        String(value).includes(startWith)
      )

      const result = input[indexOfResult + 1]
      if (key === 'nric') {
        return { [key]: result.replace('(MyKad)', '').trim() }
      } else if (key === 'telNo') {
        const index = input.lastIndexOf(startWith)
        return { [key]: input[index + 2] }
      } else if (result !== endWith) {
        return { [key]: result }
      } else return {}
    }

    const parse: string[] = input
      .split('\n')
      .map(o => o.trim())
      .filter(Boolean)

    let result: Record<string, string> = {
      productName: 'TM',
      fullName: parse[2],
      email: parse[3],
      mobileNo: parse[4],
      unitNo: parse[10],
      orderNo: parse[11],
      packageName: parse[12],
      loginId: parse[18],
    }

    const strings = input.split('\n').filter(o => !!o)

    const secondParses = [
      ['orderNo', 'Order Number', 'Customer'],
      ['nric', 'ID Number', 'Event Type'],
      ['telNo', 'Progress', 'Residential Voice Basic'],
    ].map(([key, startWith, endWith]) =>
      process(strings, {
        startWith,
        key,
        endWith,
      })
    )

    secondParses.forEach(o => {
      result = { ...result, ...o }
    })

    return {
      ...result,
    }
  }

  static maxisStringToOrderParser(input: string): Record<string, string> {
    function process(
      input: string[],
      { displayLabel, key, isNot }: Record<string, string>
    ): Record<string, string> {
      const indexOfResult = input.findIndex(value =>
        String(value).includes(displayLabel)
      )

      const result = input[indexOfResult + 1]
      if (result !== isNot) {
        return { [key]: result }
      } else return {}
    }

    const strings = input.split('\n').filter(o => !!o)

    const aaaa = [
      ['orderNo', 'Order ID', 'Remarks'],
      ['remarks', 'Remarks', 'Registration Date'],
      // ['registrationDate', 'Registration Date', 'Market'],
      ['fullName', 'Customer Name', 'MALAYSIAN ID CARD'],
      ['mobileNo', 'Contact Number', 'Alternate Contact Number'],
      ['alternativeNo', 'Alternate Contact Number', 'Customer Email'],
      ['email', 'Customer Email', 'Billing Address'],
      ['address', 'Installation Address', 'Installation Date & Time'],
      ['nric', 'MALAYSIAN ID CARD', 'Contact Number'],
      // [ 'installationDateAndTime', 'Installation Date & Time', 'Existing unifi customer', ],
      // ['unifiAccountNo', 'Unifi Account No.', 'VOIP account no'],
      ['accountNo', 'VOIP account no', 'Selected Plan'],
    ].map(([key, displayLabel, isNot]) =>
      process(strings, {
        displayLabel,
        key,
        isNot,
      })
    )

    let result: Record<string, string> = {
      productName: 'Maxis',
    }

    aaaa.forEach(o => {
      result = { ...result, ...o }
    })

    const _address = result['address']

    if (_address) {
      const temp = _address.split(',').map(o => String(o).trim())
      temp.reverse()

      const state = _.startCase(temp[0].toLowerCase())
      const combine = temp[1]
      const [postcode] = /\d{5}/.exec(combine) || []
      const city = combine.replace(postcode, '').trim()

      const section = temp[2]
      const streetName = temp[3]

      result = {
        ...result,
        streetName,
        section,
        postcode,
        city,
        state,
      }

      if (temp.length === 5) {
        const unitNo = temp[4]

        result = {
          ...result,
          unitNo,
        }
      } else if (temp.length === 6) {
        const unitNo = `${temp[5]} ${temp[4]}`

        result = {
          ...result,
          unitNo,
        }
      }
    }

    return result
  }
}

export enum OrderUploadFileKey {
  MY_KAD = 'MY_KAD',
  CONVERSATION = 'CONVERSATION',
  UTILITY = 'UTILITY',
  SSM = 'SSM',
}
