import React, { useEffect, useMemo, useState } from 'react'
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { useTypedSelector } from '../../redux/reducer'
import { useDispatch } from 'react-redux'
import { ProductAction } from '../../redux/reducer/productReducer'
import userProfileService from '../../common/service/userProfileService'
import { LoadingAction } from '../../redux/reducer/loadingBackdropReducer'
import { SnackbarAction } from '../../redux/reducer/snackbarReducer'
import OrderService from './orderService'
import ProductCategoryService from '../../adminPortal/productCategoryManagement/productCagetoryService'
import ProductModelState from './bean/productModelState'
import { OrderPostBody2 } from './bean/orderPostBody2'
import { RegexUtils } from '../../common/regexUtils'
import { catchErrorWithDispatch } from '../../common/ApiUtils'
import CommonUtils from '../../common/commonUtils'
import faker from 'faker'
import OrderAddressComponent from '../../common/orderManagement/OrderAddressComponent'
import OrderCustomerDetailComponent from '../../common/orderManagement/OrderCustomerDetailComponent'
import OrderStatus from '../../common/constant/orderStatus'
import ProductSelectionComponent from '../../common/component/ProductSelectionComponent'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { DatePicker } from '@material-ui/pickers'
import { OrderUtils } from '../../common/orderUtils'
import CommissionService from '../../adminPortal/userManagement/service/commissionService'
import { CommissionEntity } from '../../adminPortal/userManagement/bean/commissionEntity'
import CommissionRateComponent from './component/commissionRate.component'
import SelectAgentTextComponent from './component/selectAgentText.component'
import FromEmailComponent from './component/fromEmail.component'
import OrderUploadFile from '../../sharePortal/orderManagement/component/orderUploadFile'
import OrderAttachmentService from '../../sharePortal/orderAttachment/orderAttachmentService'

export interface OrderFormState {
  companyName: string
  companyRegNo: string
  fullName: string
  nric: string
  address: string
  postcode: string
  state: string
  email: string

  agentId: string
  packageId: string
  loginId: string
  eForm: string
  extra: string
  orderNo: string
  remarks: string
  mobileNo: string
  telNo: string
  documentUrl: string

  unitNo: string
  streetType: string
  streetName: string
  section: string
  floorNo: string
  buildingName: string
  city: string

  alternativeNo: string
  adminRemarks: string
  m2uRemarks: string
  orderStatus: string

  invoiceNo: string
  processingId: string
  accountNo: string
  activatedOn: string
  documentCompleted: boolean
  paymentClaimCompleted: boolean
  sporaReferenceNumber: string
  ociTicketNumber: string
  sporaProcessingId: string
}

export default function NewOrderPage() {
  const dispatch = useDispatch()
  const [files, setFiles] = useState<Record<string, File[]>>({})
  const isAgent =
    userProfileService.isAgent() || userProfileService.isAgentStaff()
  const isTopAgent =
    userProfileService.isTopAgent() || userProfileService.isAgentStaff()
  const isAgentButNotTopAgent = isAgent && !isTopAgent

  const showCommissionRate = useTypedSelector(
    state => state.portalSetting.order.showCommissionRate
  )
  const roleAbleToViewCommissionRate = useTypedSelector(
    state => state.portalSetting.order.roleAbleToViewCommissionRate
  )

  const defaultCreatedOrderStatus = useTypedSelector(
    state => state.orderSettings.defaultCreatedOrderStatus
  )

  const ableToViewCommissionRate = useMemo(
    () =>
      showCommissionRate &&
      roleAbleToViewCommissionRate.includes(
        userProfileService.getUserProfile()?.role || ''
      ),
    [roleAbleToViewCommissionRate, showCommissionRate]
  )

  /* AGENT */
  const [selectedAgentId, setSelectedAgentId] = useState<string>(
    (userProfileService.isAgent() &&
      userProfileService.getUserProfile()?.id.toString()) ||
      ''
  )

  /* END PRODUCT */

  /* PRODUCT */
  const m2uRemarksOption: Record<string, string> = {
    'Terminate streamyx, then maintain number to UniFi.':
      'Terminate streamyx, then maintain number to UniFi.',
    'Terminate streamyx and telephone number, then register new number for UniFi.':
      'Terminate streamyx and telephone number, then register new number for UniFi.',
    'Maintain streamyx and telephone number, then new service number.':
      'Maintain streamyx and telephone number, then new service number.',
  }
  const productInitialState: ProductModelState = {
    productId: '',
    categoryId: '',
    packageId: '',
    orderNo: '',
    eForm: '',
    remarks: '',
    userId: '',
    extra: '',
  }
  const [selectedProduct, setSelectedProduct] = useState(productInitialState)
  const productState = useTypedSelector(state => state.product)
  const categoryState = useTypedSelector(state => state.productCategory)
  const packageState = useTypedSelector(state => state.productPackage)
  const [m2uRemarksExist, setM2uRemarksExist] = useState<boolean>(false)
  const [selectedM2uRemarks, setSelectedM2uRemarks] = useState<string>('')
  const [commissionList, setCommissionList] = useState<CommissionEntity[]>([])

  useEffect(() => {
    dispatch(ProductAction.getProduct())
  }, [dispatch])
  useEffect(() => {
    if (selectedProduct.categoryId) {
      ProductCategoryService.extraOptionExist(
        Number(selectedProduct.categoryId)
      ).then(value => {
        setM2uRemarksExist(value.data.exist || false)
        setSelectedM2uRemarks('')
      })
    }
  }, [dispatch, selectedProduct.categoryId])

  useEffect(() => {
    if (selectedAgentId) {
      CommissionService.getCommissionByAgentId(Number(selectedAgentId)).then(
        value => {
          setCommissionList(value.data)
        }
      )
    } else {
      setCommissionList([])
    }
  }, [selectedAgentId, selectedProduct.packageId])

  function onProductIdChange(value: unknown) {
    const id = Number(value)
    setSelectedProduct({
      ...selectedProduct,
      productId: id.toString(),
      packageId: '',
      categoryId: '',
    })
  }

  function onCategoryIdChange(value: unknown) {
    const id = Number(value)
    setSelectedProduct({
      ...selectedProduct,
      productId: selectedProduct.productId,
      categoryId: id.toString(),
      packageId: '',
    })
  }

  function onPackageIdChange(value: unknown) {
    const id = Number(value)
    setSelectedProduct({
      ...selectedProduct,
      productId: selectedProduct.productId,
      categoryId: selectedProduct.categoryId,
      packageId: id.toString(),
    })
  }

  function handleM2uRemarks(
    _: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) {
    setSelectedM2uRemarks(value)
  }

  /* END PRODUCT */

  /* ORDER */
  const orderFormInitialState: OrderFormState = {
    companyName: '',
    companyRegNo: '',
    fullName: '',
    nric: '',
    address: '',
    postcode: '',
    state: '',
    email: '',
    agentId: '',
    packageId: '',
    loginId: '',
    eForm: '',
    extra: '',
    orderNo: '',
    remarks: '',
    mobileNo: '',
    telNo: '',
    documentUrl: '',
    unitNo: '',
    streetType: '',
    streetName: '',
    section: '',
    floorNo: '',
    buildingName: '',
    city: '',
    alternativeNo: '',
    adminRemarks: '',
    m2uRemarks: '',
    orderStatus: defaultCreatedOrderStatus.toString(),
    invoiceNo: '',
    processingId: '',
    accountNo: '',
    activatedOn: '',
    documentCompleted: false,
    paymentClaimCompleted: false,
    sporaReferenceNumber: '',
    ociTicketNumber: '',
    sporaProcessingId: '',
  }
  const [newOrder, setNewOrder] = useState<OrderFormState>(
    orderFormInitialState
  )
  const [newOrderError, setNewOrderError] = useState<OrderFormState>(
    orderFormInitialState
  )

  function onOrderTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    const name = e.target.name
    const value = e.target.value
    const shouldTrim = OrderUtils.shouldTrim(name)

    setNewOrder({
      ...newOrder,
      [name]: shouldTrim ? value.toString().trim() : value,
    })
  }

  const handleDateChange = (
    name: string,
    newDate: MaterialUiPickersDate | null
  ) => {
    const value = newDate ? new Date(newDate.toDateString()).toISOString() : ''

    setNewOrder(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  function handleOrderOnChange(
    event: React.ChangeEvent<{ name?: string; value: any }>
  ) {
    if (event.target.name) {
      setNewOrder({
        ...newOrder,
        [event.target.name]: event.target.value,
      })
    }
  }

  function handleCheckboxChange(name: string, value: boolean) {
    setNewOrder(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  useEffect(() => {
    setNewOrder(pre => {
      return {
        ...pre,
        orderStatus: defaultCreatedOrderStatus.toString(),
      }
    })
  }, [defaultCreatedOrderStatus])
  useEffect(() => {
    if (CommonUtils.isDevMode()) {
      const formState = {
        companyName: '',
        companyRegNo: '',
        fullName: faker.name.findName(),
        nric: faker.phone.phoneNumber('######-##-####'),
        postcode: '99999',
        state: 'Johor',
        email: faker.internet.email(),
        loginId: faker.internet.userName(),
        eForm: faker.random.uuid(),
        orderNo: faker.random.uuid(),
        mobileNo: faker.phone.phoneNumber('01#-#######'),
        telNo: faker.phone.phoneNumber('#########'),
        unitNo: faker.random.number({ min: 1, max: 100 }),
        streetType: faker.address.streetSuffix(),
        streetName: faker.address.streetAddress(),
        section: faker.address.streetName(),
        floorNo: faker.random.number({ min: 1, max: 100 }),
        buildingName: '',
        city: faker.address.city(),
        alternativeNo: '',
        adminRemarks: '',
        m2uRemarks: '',
      }

      // @ts-ignore
      setNewOrder(old => {
        return {
          ...old,
          ...formState,
        }
      })
    }
  }, [])

  /* END ORDER */

  function onSubmitClick(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const errors: Record<string, string> = {}

    if (
      !!newOrder.mobileNo &&
      !newOrder.mobileNo.match(RegexUtils.phoneNumberRegex)
    ) {
      errors.mobileNo = 'Mobile Number Wrong Format'
    }
    if (
      !!newOrder.alternativeNo &&
      !newOrder.alternativeNo.match(RegexUtils.phoneNumberRegex)
    ) {
      errors.alternativeNo = 'Tel Number Wrong Format'
    }
    if (!!newOrder.postcode && !newOrder.postcode.match(RegExp('[0-9]{5}'))) {
      errors.postcode = 'Postcode Wrong Format'
    }

    setNewOrderError({ ...orderFormInitialState, ...errors })
    if (Object.keys(errors).length) {
      dispatch(SnackbarAction.openError('Form have error, please change.'))
      return
    }

    const postBody: OrderPostBody2 = {
      agentId: Number(selectedAgentId),
      eForm: newOrder.eForm,
      loginId: newOrder.loginId,
      orderNo: newOrder.orderNo,
      packageId: Number(selectedProduct.packageId),
      remarks: newOrder.remarks,
      email: newOrder.email,
      mobileNo: newOrder?.mobileNo,
      telNo: newOrder?.telNo,
      address: newOrder.address,
      postcode: newOrder.postcode,
      state: newOrder.state,
      nric: newOrder.nric,
      companyName: newOrder.companyName,
      companyRegNo: newOrder.companyRegNo,
      fullName: newOrder.fullName,

      unitNo: newOrder?.unitNo,
      streetType: newOrder?.streetType,
      streetName: newOrder?.streetName,
      section: newOrder?.section,
      floorNo: newOrder?.floorNo,
      buildingName: newOrder?.buildingName,
      city: newOrder.city,

      alternativeNo: newOrder.alternativeNo,
      adminRemarks: newOrder.adminRemarks,
      m2uRemarks: selectedM2uRemarks,

      orderStatus: newOrder.orderStatus,

      invoiceNo: newOrder.invoiceNo,
      processingId: newOrder.processingId,
      accountNo: newOrder.accountNo,
      activatedOn: newOrder.activatedOn,

      documentCompleted: newOrder.documentCompleted,
      paymentClaimCompleted: newOrder.paymentClaimCompleted,

      sporaReferenceNumber: newOrder.sporaReferenceNumber,
      ociTicketNumber: newOrder.ociTicketNumber,
      sporaProcessingId: newOrder.sporaProcessingId,
    }

    dispatch(LoadingAction.open('Creating new order...'))

    OrderService.postOrderV2(postBody)
      .then(async ({ data: resp }) => {
        await Promise.all(
          Object.entries(files).map(entry => {
            const [category, file] = entry
            return OrderAttachmentService.createOrderAttachmentWithCategory(
              resp.id,
              file,
              category
            )
          })
        )
        setFiles({})
        dispatch(SnackbarAction.open(`Success Create Order.`))
        onResetClick()
      })
      .catch(catchErrorWithDispatch(dispatch))
      .finally(() => {
        dispatch(LoadingAction.close())
      })
  }

  function onResetClick() {
    setSelectedAgentId(
      (userProfileService.isAgent() &&
        userProfileService.getUserProfile()?.id.toString()) ||
        ''
    )
    setSelectedProduct(productInitialState)
    setNewOrder(orderFormInitialState)
    setNewOrderError(orderFormInitialState)
    setM2uRemarksExist(false)
    setSelectedM2uRemarks('')
  }

  function renderOrderStatusSelect() {
    // top agent only able chan¡ge to
    // [OrderStatus.CANCEL, OrderStatus.EARLY_STAGE, OrderStatus.PENDING]

    const orderStatus = [
      OrderStatus.AGENT_SUBMITTED,
      OrderStatus.PENDING,
      OrderStatus.WAITING_LIST,
      OrderStatus.EARLY_STAGE,
      OrderStatus.NEW_AREA,
      OrderStatus.KIV,
      OrderStatus.CANCEL,
      OrderStatus.POST_COMPLETE,
    ]

    const isStatusDisable = {
      [OrderStatus.AGENT_SUBMITTED]: false,
      [OrderStatus.PENDING]: isAgentButNotTopAgent,
      [OrderStatus.EARLY_STAGE]: isAgentButNotTopAgent,
      [OrderStatus.WAITING_LIST]: isAgent,
      [OrderStatus.NEW_AREA]: isAgent,
      [OrderStatus.KIV]: isAgent,
      [OrderStatus.CANCEL]: isAgentButNotTopAgent,
      [OrderStatus.POST_COMPLETE]: isAgent,
    }

    // Agent not able to change order status.
    // Top agent can change to certain order status.
    return (
      <FormControl
        className='col-span-2'
        required
        disabled={isAgentButNotTopAgent}
      >
        <InputLabel id='state'>Order Status</InputLabel>
        <Select
          id='orderStatus'
          name='orderStatus'
          value={newOrder['orderStatus']}
          onChange={handleOrderOnChange}
        >
          {orderStatus.map(status => (
            <MenuItem
              key={status}
              value={status}
              disabled={isStatusDisable[status]}
            >
              {OrderStatus.toDisplay[status]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  return (
    <div>
      <AppBar position='relative'>
        <Toolbar className='space-x-3 justify-between'>
          <Typography variant='h6' noWrap>
            New Orders
          </Typography>
          <div className='text-right'>
            <FromEmailComponent
              onSubmit={result => {
                const { packageName, productName, ...rest } = result

                if (productName) {
                  const _product = productState.products.find(o =>
                    o.name.toLowerCase().includes(productName.toLowerCase())
                  )

                  setSelectedProduct(pre => {
                    return {
                      ...pre,
                      productId: _product?.id,
                    }
                  })
                }

                const _package = packageState.packages.find(
                  o => o.name === packageName
                )

                console.log({ packageName, package: _package })
                if (_package) {
                  const category = categoryState.categories.find(
                    o => o.id === _package.categoryId
                  )

                  console.log({ category })
                  setSelectedProduct(pre => {
                    return {
                      ...pre,
                      packageId: _package.id,
                      categoryId: _package.categoryId,
                      productId: category.productId,
                    }
                  })
                }

                setNewOrder(pre => {
                  return {
                    ...pre,
                    ...rest,
                  }
                })
              }}
            />
          </div>
        </Toolbar>
      </AppBar>
      <form onSubmit={onSubmitClick}>
        <Card className='m-4'>
          <CardHeader title={'Product'} />
          <CardContent>
            <ProductSelectionComponent
              disabled={false}
              onProductChange={value => {
                if (value) {
                  onProductIdChange(value.id)
                }
              }}
              onCategoryChange={value => {
                if (value) {
                  onCategoryIdChange(value.id)
                }
              }}
              onPackageChange={value => {
                if (value) {
                  onPackageIdChange(value.id)
                }
              }}
              productValue={
                productState.products.find(
                  value =>
                    value.id.toString() === selectedProduct.productId.toString()
                ) || null
              }
              categoryValue={
                categoryState.categories.find(
                  value =>
                    value.id.toString() ===
                    selectedProduct.categoryId.toString()
                ) || null
              }
              packageValue={
                packageState.packages.find(
                  value =>
                    value.id.toString() === selectedProduct.packageId.toString()
                ) || null
              }
            />
            <SelectAgentTextComponent
              disabled={false}
              setAgentId={setSelectedAgentId}
              agentId={selectedAgentId || ''}
              params={{ excludeSelf: false, excludeDisable: true }}
            />
            <div>
              {ableToViewCommissionRate && selectedAgentId && (
                <CommissionRateComponent
                  packageId={selectedProduct.packageId}
                  packages={packageState.packages}
                  commissions={commissionList}
                />
              )}
            </div>
          </CardContent>
        </Card>

        <Card className='m-4'>
          <CardHeader title={'Order Detail'} />
          <CardContent className='space-y-3'>
            <div className='grid-cols-2 grid gap-4'>
              {renderOrderStatusSelect()}

              {!isAgent && (
                <>
                  <TextField
                    name='invoiceNo'
                    label='Reference Invoice No'
                    value={newOrder['invoiceNo']}
                    onChange={onOrderTextChange}
                  />
                  <TextField
                    name='processingId'
                    label='Processing ID'
                    value={newOrder['processingId']}
                    onChange={onOrderTextChange}
                  />
                  <TextField
                    name='accountNo'
                    label='Account No'
                    value={newOrder['accountNo']}
                    onChange={onOrderTextChange}
                  />
                  <div />
                  <FormControlLabel
                    checked={newOrder.documentCompleted}
                    onChange={(event, checked) => {
                      handleCheckboxChange('documentCompleted', checked)
                    }}
                    control={<Checkbox name='documentCompleted' />}
                    label='Document Completed'
                  />
                  <FormControlLabel
                    checked={newOrder.paymentClaimCompleted}
                    onChange={(event, checked) => {
                      handleCheckboxChange('paymentClaimCompleted', checked)
                    }}
                    control={<Checkbox name='paymentClaimCompleted' />}
                    label='Payment Claim Completed'
                  />
                </>
              )}

              <TextField
                id='orderNo'
                name='orderNo'
                label='Order No'
                value={newOrder['orderNo']}
                onChange={onOrderTextChange}
                inputProps={{
                  inputMode: 'text',
                  pattern: '[A-Za-z0-9-]+',
                  maxLength: 16
                }}
                helperText={
                  'Only alphanumeric(a-z, 0-9) and dash(-), max 16 characters'
                }
                fullWidth
                required={newOrder['orderStatus'] === OrderStatus.POST_COMPLETE}
              />
              <TextField
                id='eForm'
                name='eForm'
                label='eForm (TM-Only)'
                value={newOrder['eForm']}
                onChange={onOrderTextChange}
                fullWidth
              />
              <TextField
                id='loginId'
                name='loginId'
                label='User ID'
                value={newOrder['loginId']}
                onChange={onOrderTextChange}
                fullWidth
              />

              <DatePicker
                clearable
                format='dd MMM yyyy'
                name='activatedOn'
                label='Activated Date'
                value={newOrder['activatedOn'] || null}
                onChange={(e: MaterialUiPickersDate) => {
                  handleDateChange('activatedOn', e)
                }}
              />

              <TextField
                id='ociTicketNumber'
                name='ociTicketNumber'
                label='OCI Ticket Number'
                value={newOrder['ociTicketNumber']}
                onChange={onOrderTextChange}
                fullWidth
              />
              <div />

              <TextField
                id='sporaReferenceNumber'
                name='sporaReferenceNumber'
                label='Spora Reference Number'
                value={newOrder['sporaReferenceNumber']}
                onChange={onOrderTextChange}
                fullWidth
              />

              <TextField
                id='sporaProcessingId'
                name='sporaProcessingId'
                label='Spora Processing Id'
                value={newOrder['sporaProcessingId']}
                onChange={onOrderTextChange}
                fullWidth
              />
            </div>

            <TextField
              id='remarks'
              name='remarks'
              label='Remarks'
              value={newOrder['remarks']}
              onChange={onOrderTextChange}
              fullWidth
              multiline
              rows='4'
            />
            <TextField
              id='adminRemarks'
              name='adminRemarks'
              label='Admin Remarks'
              value={newOrder['adminRemarks']}
              onChange={onOrderTextChange}
              fullWidth
              multiline
              rows='4'
            />
            {m2uRemarksExist && (
              <FormControl component='fieldset' required={true}>
                <FormLabel component='legend'>M2U Remarks</FormLabel>
                <RadioGroup
                  value={selectedM2uRemarks}
                  onChange={handleM2uRemarks}
                >
                  {Object.entries(m2uRemarksOption).map(([id, str]) => {
                    return (
                      <FormControlLabel
                        key={id}
                        value={str}
                        control={<Radio required={true} />}
                        label={str}
                      />
                    )
                  })}
                </RadioGroup>
              </FormControl>
            )}
          </CardContent>
        </Card>

        <Card className='m-4'>
          <CardHeader title={'Customer Detail'} />
          <CardContent>
            <OrderCustomerDetailComponent
              isCreateMode={true}
              form={newOrder}
              errorForm={newOrderError}
              onChange={(name, value) => {
                setNewOrder(prevState => ({
                  ...prevState,
                  [name]: value,
                }))
              }}
            />
          </CardContent>
        </Card>

        <Card className='m-4'>
          <CardHeader title={'Address'} />
          <CardContent className='space-y-3'>
            <OrderAddressComponent
              form={{
                unitNo: newOrder.unitNo,
                streetType: newOrder.streetType,
                streetName: newOrder.streetName,
                section: newOrder.section,
                floorNo: newOrder.floorNo,
                buildingName: newOrder.buildingName,
                city: newOrder.city,
                state: newOrder.state,
                postcode: newOrder.postcode,
              }}
              errorForm={newOrderError}
              onChange={(name, value) => {
                setNewOrder(prevState => ({
                  ...prevState,
                  [name]: value,
                }))
              }}
            />
          </CardContent>
        </Card>

        <Card className='m-4'>
          <CardHeader title={'Upload File'} />
          <CardContent>
            <OrderUploadFile files={files} onChange={setFiles} />
          </CardContent>
        </Card>

        <CardActions className='m-4 justify-end'>
          <Button color='secondary' size={'large'} onClick={onResetClick}>
            RESET
          </Button>

          <Button
            color='primary'
            variant='contained'
            type='submit'
            size={'large'}
          >
            Submit
          </Button>
        </CardActions>
      </form>
    </div>
  )
}
